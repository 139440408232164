import {
  createMutationByInstance,
  MethodNameNull,
  MutationAbstract,
} from '../../../common/mutation';
import {
  ContractSendArguments,
  EvmSendArgumentsDto,
  EvmSendResultParsed,
  IContract,
  Method,
  MutationOptions,
} from '../../../types';
import { parseEvmEvents } from '../utils';

class ContractSendMutation extends MutationAbstract<
  EvmSendArgumentsDto,
  EvmSendResultParsed
> {
  constructor(private readonly contract: IContract) {
    super(contract.client, Method.POST, 'evm/send', MethodNameNull);
  }

  public override async build(args: ContractSendArguments) {
    return super.build({
      contractAddress: this.contract.contractAddress,
      abi: this.contract.abi,
      ...args,
    });
  }

  public override async submitWaitResult(args, options: MutationOptions) {
    const result = await super.submitWaitResult(args, options);

    if (result.parsed.isExecutedFailed) {
      await this.contract.call(args);
    }

    const { parsedEvents, unknownEvents } = parseEvmEvents(
      result.events,
      this.contract.abi,
    );

    result.parsed.parsedEvents = parsedEvents;
    result.parsed.unknownEvents = unknownEvents;

    return result;
  }
}

export function createContractSendMutation(contract: IContract) {
  const mutation = new ContractSendMutation(contract);
  return createMutationByInstance(mutation);
}
