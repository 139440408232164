import React, { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useApi } from 'hooks/useApi';
import { shortcutText } from 'utils/textUtils';
import { Text } from 'components/UI';
import { Picture } from '..';
import { AdditionalDark, AdditionalLight } from 'styles/colors';
import { CollectionInfoWithSchemaResponse } from '@unique-nft/sdk';
import { getIpfsUriByImagePath } from 'utils/urlUtils';
import { GQLCollection } from 'api/scanApi/types';

export type TCollectionCard = {
  collection: GQLCollection

  onViewOnScanClick(): void
};

export const CollectionCard: FC<TCollectionCard> = ({
  collection,
  onViewOnScanClick
}) => {
  const { featuredCollections, currentChainId } = useApi();
  const [collectionDetails, setCollectionDetails] = useState<CollectionInfoWithSchemaResponse | null>();
  useEffect(() => {
    if (!collection?.id) return;
    setCollectionDetails(featuredCollections[collection.id]?.description);
  }, [collection, featuredCollections]);

  const hasSponsorship = useMemo(() => collectionDetails?.sponsorship && collectionDetails?.sponsorship?.isConfirmed, [collectionDetails]);
  const hasUnconfirmedSponsorship = useMemo(() => collectionDetails?.sponsorship && !collectionDetails?.sponsorship?.isConfirmed, [collectionDetails]);

  return (
    <CollectionCardStyled>
      <PictureWrapper>
        <Link to={`/${currentChainId}/collection/${collection?.id}`}>
          <Picture alt={collection?.id?.toString() || ''} src={getIpfsUriByImagePath(collection.cover || '')} />
        </Link>
      </PictureWrapper>
      <Description>
        <Link to={`/${currentChainId}/collection/${collection?.id}`}>
          <Text size='l' weight='regular'>
            {`${collection.name || ''} [id ${collection?.id}]`}
          </Text>
        </Link>
        <AttributesWrapper>
          {hasSponsorship && <Row>
            <Text size='s' color={'grey-500'} >Sponsor:</Text>
            <Text size='s' >{collectionDetails?.sponsorship?.isConfirmed ? shortcutText(collectionDetails?.sponsorship?.address || '') : 'not assigned'}</Text>
          </Row>}
          {hasUnconfirmedSponsorship && <Row>
            <Text size='s' color={'coral-500'} >Waiting for sponsorship approval</Text>
          </Row>}
        </AttributesWrapper>
      </Description>
    </CollectionCardStyled>
  );
};

const CollectionCardStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
`;

const PictureWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  .picture {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: white;
    text-align: center;
    max-height: 100%;
    border-radius: 8px;
    transition: 50ms;
    overflow: hidden;
    
    img {    
      // changed it according to the task DAP-290
      object-fit: contain;
      height: 100%;
      width: 100%;
      min-height: 100%;
    }

    svg {
      border-radius: 8px;
    }
  }
`;

const ActionsMenuWrapper = styled.div`
  position: absolute;
  top: calc(var(--prop-gap) / 2);
  right: calc(var(--prop-gap) / 2);
  
  div[class*=DropdownMenuButtonWrapper] {
    & > button {
      padding: 4px;
      background-color: ${AdditionalDark};
      border: 2px solid #FFFFFF;
      border-radius: 4px;
      color: ${AdditionalLight};
      height: 32px;
      &:hover {
        background-color: ${AdditionalDark};
      }
    }
    & > img {
      display: none;
    }
  }
  div[class*=DropdownMenuDropdown] {
    width: max-content;
    & > div {
      padding: calc(var(--prop-gap) / 2) var(--prop-gap);
      display: flex;
    }
  }
  
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
`;

const AttributesWrapper = styled.div` 
  display: flex;
  flex-direction: column;
  row-gap: calc(var(--prop-gap) / 4);
`;

const Row = styled.div` 
  && {
    display: flex;
    align-items: center;
    column-gap: calc(var(--prop-gap) / 4);
  }
`;

const IconWrapper = styled.div`
  && {
    width: 16px;
    height: 16px;
    color: var(--color-primary-500);
    padding: 0;

    path {
      stroke: currentColor;
    }
  }
`;

const DropdownWrapper = styled.div`
  background: var(--color-additional-dark);
  border-radius: 4px;
  padding: calc(var(--prop-gap) / 4) calc(var(--prop-gap) / 4) 0 calc(var(--prop-gap) / 4);
`;

const DropdownMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 200px;
`;

const DropdownMenuItem = styled.div`
  display: flex;
  padding: var(--prop-gap);
  cursor: pointer;
  &:hover {
    background: var(--color-primary-100);
    color: var(--color-primary-500);
  }
`;
