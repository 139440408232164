import React, { useEffect, useState } from 'react';
import { useApi } from 'hooks/useApi';

import { useNavigate, useParams } from 'react-router-dom';
import { CollectionDetail } from './CollectionDetail/CollectionDetail';
import { CollectionWithInfoV2Dto } from '@unique-nft/sdk';
import { Error404 } from 'pages/Errors/404';
import { RFTCollectionPage } from './RFTCollection';
import { NFTCollectionPage } from './NFTCollection';

export const CollectionPage = () => {
  const { collectionId } = useParams<{ collectionId: string }>();
  const [collection, setCollection] = useState<CollectionWithInfoV2Dto | null>();
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const { currentChainId, api } = useApi();
  const navigate = useNavigate();

  const isRFTCollection = collection?.mode === 'ReFungible';

  useEffect(() => {
    if (!api?.collection) return;
    const id = Number(collectionId);
    void (async () => {
      try {
        const collection = await api?.collection?.getCollection(id);

        setCollection(collection);
      } catch (err) {
        navigate(`/${currentChainId}/collections`);
      }
      setIsFetching(false);
    })();
  }, [currentChainId, collectionId, api?.collection]);

  if (isFetching) return <CollectionDetail />;

  if (!collection) return <Error404 />;

  if (isRFTCollection) return <RFTCollectionPage collection={collection} />;

  return <NFTCollectionPage collection={collection} />;
};
