import { useEffect, useMemo, useState } from 'react';
import { Address } from '@unique-nft/utils';

import { Heading, Alert, TooltipWrapper, Button, InputText, Text, useNotifications, ButtonGroup } from 'components/UI';

import { ConfirmSponsorship } from './ConfirmSponsorship';
import { SettingsSavingProps } from './types';
import { RemoveSponsorship } from './RemoveSponsorship';
import { useAccounts } from 'hooks/useAccounts';
import { useCollectionSetSponsor } from './hooks/useCollectionSetSponsor';
import { Form, FormBody, FormRow, SettingsRow } from 'pages/CreateCollection/components/FormComponents';
import { StatusTransactionModal } from './StatusTransactionModal';
import { ConfirmUpdateCollectionModal } from './ConfirmUpdateCollectionModal';
import { FeeMessage } from 'components';

export const Sponsorship = ({ collectionInfo, onComplete }: SettingsSavingProps) => {
  const [isVisibleConfirmModal, setVisibleConfirmModal] = useState(false);
  const { selectedAccount } = useAccounts();
  const {
    feeFormatted,
    getFee,
    feeError,
    feeLoading,
    submitWaitResult,
    isLoadingSubmitResult
  } = useCollectionSetSponsor();
  const { error, info } = useNotifications();

  const { sponsorship, collectionId } = collectionInfo || {};

  const [sponsor, setSponsor] = useState<string | undefined>(sponsorship?.address);

  const isValid = useMemo(() => {
    return !sponsor || Address.is.substrateAddressInAnyForm(sponsor);
  }, [sponsor]);

  const isChanged = useMemo(() => {
    return sponsor !== sponsorship?.address;
  }, [sponsor, sponsorship]);

  const canConfirmSponsorship = useMemo(() => {
    return (
      !sponsorship?.isConfirmed &&
      selectedAccount?.address.toLowerCase() === sponsorship?.address.toLowerCase()
    );
  }, [sponsorship, selectedAccount?.address]);

  useEffect(() => {
    setSponsor(sponsorship?.address);
  }, [sponsorship]);

  useEffect(() => {
    if (feeError) {
      error(feeError);
    }
  }, [feeError]);

  useEffect(() => {
    if (
      !isChanged ||
      !collectionId ||
      !selectedAccount?.address ||
      !isValid ||
      !sponsor
    ) {
      return;
    }
    getFee({
      payload: {
        collectionId,
        address: selectedAccount?.address,
        newSponsor: sponsor || ''
      }
    });
  }, [collectionId, sponsor, getFee, selectedAccount?.address]);

  const handleBurnCollection = async () => {
    if (!collectionId || !selectedAccount || !isValid || !sponsor) {
      return;
    }

    setVisibleConfirmModal(false);

    try {
      await submitWaitResult(
        {
          payload: {
            collectionId,
            address: selectedAccount.address,
            newSponsor: sponsor || ''
          }
        }
      );
      await onComplete?.();
      info('The collection sponsor set successfully');
    } catch (e: any) {
      setVisibleConfirmModal(false);
      error(e.message);
    }
  };

  return (
    <>
      <FormBody>
        <Form>
          <FormRow>
            <Heading size='4'>Collection sponsor</Heading>
            <Text appearance='block'>
              An&nbsp;address from which all transaction fees related
              to&nbsp;the&nbsp;collection are paid from (i.e.&nbsp;a&nbsp;sponsoring fund
              address).
            </Text>
            <Text appearance='block'>
              This can be&nbsp;a&nbsp;regular account or&nbsp;a&nbsp;market contract
              address.
            </Text>
          </FormRow>
          <SettingsRow>
            <InputText
              id='address'
              label='Address'
              value={sponsor}
              error={!isValid}
              statusText={!isValid ? 'Sponsor address is not correct' : ''}
              onChange={setSponsor}
            />
          </SettingsRow>
          <SettingsRow>
            {isValid && isChanged && sponsor
              ? (
                <FeeMessage fee={feeFormatted} isFeeLoading={feeLoading} testid={'fee'} />
                          )
              : (
                <Alert type='warning'>
                  A fee will be&nbsp;calculated after changing the&nbsp;sponsor address
                </Alert>
            )}
          </SettingsRow>
          <ButtonGroup $fill
            align='flex-start'
            gap={16}
            className='btn-group'
          >
            <TooltipWrapper
              message={
                !isValid
                  ? 'Sponsor address is not correct'
                  : !isChanged
                  ? 'Nothing changed'
                  : !sponsor
                  ? 'Sponsor address cannot be empty'
                  : ''
              }
            >
              <Button
                title='Save changes'
                disabled={!isValid || !isChanged || !sponsor}
                onClick={() => setVisibleConfirmModal(true)}
              />
            </TooltipWrapper>
            {sponsorship?.address && (
              <RemoveSponsorship collectionId={collectionId} onComplete={onComplete} />
            )}
            {canConfirmSponsorship && (
              <ConfirmSponsorship collectionId={collectionId} onComplete={onComplete} />
            )}
          </ButtonGroup>
        </Form>
      </FormBody>

      <StatusTransactionModal
        isVisible={isLoadingSubmitResult}
        description='Updating collection sponsor'
      />

      <ConfirmUpdateCollectionModal
        title='Updating collection sponsor'
        isVisible={isVisibleConfirmModal}
        isLoading={feeLoading}
        fee={feeFormatted}
        onConfirm={handleBurnCollection}
        onClose={() => setVisibleConfirmModal(false)}
      />
    </>
  );
};
