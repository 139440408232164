import {
  NftsPreSignMintArguments,
  NftsPreSignMintResult,
  NftsPreSignSetAttributeArguments,
  NftsPreSignSetAttributeResult,
} from '@unique-nft/common/types/nfts';
import { Section } from '../../common';
import { INftsPreSign, Method } from '../../types';
import { createMutationEx } from '../../common/mutation';

export class NftsPreSign extends Section implements INftsPreSign {
  readonly mint = createMutationEx<
    NftsPreSignMintArguments,
    NftsPreSignMintResult
  >(this.client, Method.POST, `${this.path}/mint`, {
    section: 'nfts',
    method: 'mintPreSigned',
  });

  readonly setAttribute = createMutationEx<
    NftsPreSignSetAttributeArguments,
    NftsPreSignSetAttributeResult
  >(this.client, Method.POST, `${this.path}/set-attribute`, {
    section: 'nfts',
    method: 'setAttributesPreSigned',
  });
}
