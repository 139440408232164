import {
  NftsItemApproveAttributesArguments,
  NftsItemApproveAttributesResult,
  NftsItemApproveTransferArguments,
  NftsItemApproveTransferResult,
  NftsItemAttributesApprovalsResult,
  NftsItemBurnResult,
  NftsItemBuyArguments,
  NftsItemBuyResult,
  NftsItemCancelApprovalArguments,
  NftsItemCancelApprovalResult,
  NftsItemCancelAttributesApprovalArguments,
  NftsItemCancelAttributesApprovalResult,
  NftsItemCancelSwapResult,
  NftsItemClaimSwapArguments,
  NftsItemClaimSwapResult,
  NftsItemClearAllTransferApprovalResult,
  NftsItemClearAttributeArguments,
  NftsItemClearAttributeResult,
  NftsItemClearMetadataResult,
  NftsItemCreateSwapArguments,
  NftsItemCreateSwapResult,
  NftsItemExtrinsicArguments,
  NftsItemGetArguments,
  NftsItemGetAttributeArguments,
  NftsItemGetAttributeResult,
  NftsItemGetAttributesArguments,
  NftsItemGetAttributesResult,
  NftsItemGetConfigResult,
  NftsItemGetMetadataResult,
  NftsItemGetPendingSwapResult,
  NftsItemGetPriceResult,
  NftsItemGetResult,
  NftsItemIsOwnerArguments,
  NftsItemIsOwnerResult,
  NftsItemLockPropertiesArguments,
  NftsItemLockPropertiesResult,
  NftsItemLockTransferResult,
  NftsItemMintArguments,
  NftsItemMintResult,
  NftsItemPayTipsArguments,
  NftsItemPayTipsResult,
  NftsItemRedepositArguments,
  NftsItemRedepositResult,
  NftsItemRemovePriceResult,
  NftsItemSetAttributeArguments,
  NftsItemSetAttributeResult,
  NftsItemSetMetadataArguments,
  NftsItemSetMetadataResult,
  NftsItemSetPriceArguments,
  NftsItemSetPriceResult,
  NftsItemTransferArguments,
  NftsItemTransferResult,
} from '@unique-nft/common/types/nfts';
import { Section } from '../../common';
import { INftsItem, Method } from '../../types';
import { createMutationEx, MethodNameNull } from '../../common/mutation';
import { decomposeNamespace } from './utils';

export class NftsItem extends Section implements INftsItem {
  readonly mint = createMutationEx<NftsItemMintArguments, NftsItemMintResult>(
    this.client,
    Method.POST,
    `${this.path}/mint`,
    {
      section: 'nfts',
      method: 'mint',
    },
  );

  readonly get = (args: NftsItemGetArguments): Promise<NftsItemGetResult> =>
    this.query('', args);

  readonly isOwner = (
    args: NftsItemIsOwnerArguments,
  ): Promise<NftsItemIsOwnerResult> => this.query('is-owner', args);

  readonly setPrice = createMutationEx<
    NftsItemSetPriceArguments,
    NftsItemSetPriceResult
  >(this.client, Method.POST, `${this.path}/set-price`, {
    section: 'nfts',
    method: 'setPrice',
  });

  readonly buy = createMutationEx<NftsItemBuyArguments, NftsItemBuyResult>(
    this.client,
    Method.POST,
    `${this.path}/buy`,
    {
      section: 'nfts',
      method: 'buyItem',
    },
  );

  readonly removePrice = createMutationEx<
    NftsItemExtrinsicArguments,
    NftsItemRemovePriceResult
  >(this.client, Method.POST, `${this.path}/remove-price`, MethodNameNull);

  readonly getPrice = (
    args: NftsItemGetArguments,
  ): Promise<NftsItemGetPriceResult> => this.query('get-price', args);

  readonly setMetadata = createMutationEx<
    NftsItemSetMetadataArguments,
    NftsItemSetMetadataResult
  >(this.client, Method.POST, `${this.path}/set-metadata`, {
    section: 'nfts',
    method: 'setMetadata',
  });

  readonly getMetadata = (
    args: NftsItemGetArguments,
  ): Promise<NftsItemGetMetadataResult> => this.query('get-metadata', args);

  readonly clearMetadata = createMutationEx<
    NftsItemExtrinsicArguments,
    NftsItemClearMetadataResult
  >(this.client, Method.POST, `${this.path}/clear-metadata`, {
    section: 'nfts',
    method: 'clearMetadata',
  });

  readonly getConfig = (
    args: NftsItemGetArguments,
  ): Promise<NftsItemGetConfigResult> => this.query('get-config', args);

  readonly lockTransfer = createMutationEx<
    NftsItemExtrinsicArguments,
    NftsItemLockTransferResult
  >(this.client, Method.POST, `${this.path}/lock-transfer`, {
    section: 'nfts',
    method: 'lockItemTransfer',
  });

  readonly unlockTransfer = createMutationEx<
    NftsItemExtrinsicArguments,
    NftsItemLockTransferResult
  >(this.client, Method.POST, `${this.path}/unlock-transfer`, {
    section: 'nfts',
    method: 'unlockItemTransfer',
  });

  readonly transfer = createMutationEx<
    NftsItemTransferArguments,
    NftsItemTransferResult
  >(this.client, Method.POST, `${this.path}/transfer`, {
    section: 'nfts',
    method: 'transfer',
  });

  readonly approveTransfer = createMutationEx<
    NftsItemApproveTransferArguments,
    NftsItemApproveTransferResult
  >(this.client, Method.POST, `${this.path}/approve-transfer`, {
    section: 'nfts',
    method: 'approveTransfer',
  });

  readonly cancelApproval = createMutationEx<
    NftsItemCancelApprovalArguments,
    NftsItemCancelApprovalResult
  >(this.client, Method.POST, `${this.path}/cancel-approval`, {
    section: 'nfts',
    method: 'cancelApproval',
  });

  readonly clearAllTransferApproval = createMutationEx<
    NftsItemExtrinsicArguments,
    NftsItemClearAllTransferApprovalResult
  >(this.client, Method.POST, `${this.path}/clear-all-transfer-approval`, {
    section: 'nfts',
    method: 'clearAllTransferApprovals',
  });

  readonly burn = createMutationEx<
    NftsItemExtrinsicArguments,
    NftsItemBurnResult
  >(this.client, Method.DELETE, `${this.path}/burn`, {
    section: 'nfts',
    method: 'burn',
  });

  readonly lockProperties = createMutationEx<
    NftsItemLockPropertiesArguments,
    NftsItemLockPropertiesResult
  >(this.client, Method.POST, `${this.path}/lock-properties`, {
    section: 'nfts',
    method: 'lockItemProperties',
  });

  readonly setAttribute = createMutationEx<
    NftsItemSetAttributeArguments,
    NftsItemSetAttributeResult
  >(this.client, Method.POST, `${this.path}/set-attribute`, {
    section: 'nfts',
    method: 'setAttribute',
  });

  readonly getAttribute = (
    args: NftsItemGetAttributeArguments,
  ): Promise<NftsItemGetAttributeResult> =>
    this.query('get-attribute', decomposeNamespace(args));

  readonly getAttributes = (
    args: NftsItemGetAttributesArguments,
  ): Promise<NftsItemGetAttributesResult> =>
    this.query('get-attributes', decomposeNamespace(args));

  readonly clearAttribute = createMutationEx<
    NftsItemClearAttributeArguments,
    NftsItemClearAttributeResult
  >(this.client, Method.POST, `${this.path}/clear-attribute`, {
    section: 'nfts',
    method: 'clearAttribute',
  });

  readonly approveAttributes = createMutationEx<
    NftsItemApproveAttributesArguments,
    NftsItemApproveAttributesResult
  >(this.client, Method.POST, `${this.path}/approve-attributes`, {
    section: 'nfts',
    method: 'approveItemAttributes',
  });

  readonly cancelAttributesApproval = createMutationEx<
    NftsItemCancelAttributesApprovalArguments,
    NftsItemCancelAttributesApprovalResult
  >(this.client, Method.POST, `${this.path}/cancel-attributes-approval`, {
    section: 'nfts',
    method: 'cancelItemAttributesApproval',
  });

  readonly getAttributesApprovals = (
    args: NftsItemGetArguments,
  ): Promise<NftsItemAttributesApprovalsResult> =>
    this.query('get-attributes-approvals', args);

  readonly createSwap = createMutationEx<
    NftsItemCreateSwapArguments,
    NftsItemCreateSwapResult
  >(this.client, Method.POST, `${this.path}/create-swap`, {
    section: 'nfts',
    method: 'createSwap',
  });

  readonly cancelSwap = createMutationEx<
    NftsItemExtrinsicArguments,
    NftsItemCancelSwapResult
  >(this.client, Method.POST, `${this.path}/cancel-swap`, {
    section: 'nfts',
    method: 'cancelSwap',
  });

  readonly claimSwap = createMutationEx<
    NftsItemClaimSwapArguments,
    NftsItemClaimSwapResult
  >(this.client, Method.POST, `${this.path}/claim-swap`, {
    section: 'nfts',
    method: 'claimSwap',
  });

  readonly getPendingSwap = (
    args: NftsItemGetArguments,
  ): Promise<NftsItemGetPendingSwapResult> =>
    this.query('get-pending-swap', args);

  readonly redeposit = createMutationEx<
    NftsItemRedepositArguments,
    NftsItemRedepositResult
  >(this.client, Method.POST, `${this.path}/redeposit`, {
    section: 'nfts',
    method: 'redeposit',
  });

  readonly payTips = createMutationEx<
    NftsItemPayTipsArguments,
    NftsItemPayTipsResult
  >(this.client, Method.POST, `${this.path}/pay-tips`, {
    section: 'nfts',
    method: 'payTips',
  });
}
