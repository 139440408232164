import { io } from 'socket.io-client';
import { Options, SubscriptionConnectOptions } from '../../types';
import { SocketClient } from './socket-client';

export class Subscription {
  readonly options: Options;

  readonly namespace = '/v1';

  constructor({ options }: { options: Options }) {
    this.options = options;
  }

  getDefaultSocketOptions(): SubscriptionConnectOptions {
    const url = new URL(this.options.baseUrl);

    const uri = `${url.origin}${this.namespace}`;
    const path = url.pathname.replace('/v1', '/socket.io');

    return {
      uri,
      path,
      reconnection: true,
      autoConnect: true,
      transports: ['websocket'],
    };
  }

  public connect(options?: SubscriptionConnectOptions): SocketClient {
    const { uri, ...restOptions } = {
      ...options,
      ...this.getDefaultSocketOptions(),
    };

    const socket = io(uri, restOptions);

    return new SocketClient(socket);
  }
}
