export class TimeoutError extends Error {
  details: string;

  txHash: string;

  timeoutMs: number;

  constructor(txHash: string, timeoutMs: number) {
    const message = `Extrinsic status was not changed within the specified time (${timeoutMs} ms).`;

    super(message);
    this.details =
      `${message} This does not mean that the Extrinsic has failed nor that it will be included in the future.` +
      ' You can check the status of the Extrinsic by calling the getStatus() method.';

    this.txHash = txHash;
    this.timeoutMs = timeoutMs;
  }
}
