import { Link } from 'react-router-dom';
import { Icon, Link as UILink } from 'components/UI';

const cryptoExchangeUNQ = 'https://www.huobi.com/en-us/exchange/unq_usdt';

export const faqItems = <T extends Record<string, unknown>>(
  activeNetwork: string,
  state: T
) => {
  return [
    {
      title: <>How can I&nbsp;create or&nbsp;connect my&nbsp;account?</>,
      content: (
        <>
          <p>
            Use the “
            <Link to={`/${activeNetwork}/accounts`}>Connect wallet</Link>”
            button in&nbsp;the upper right corner of&nbsp;the screen and follow the
            instructions. You can&nbsp;choose between Polkadot.js and Metamask or&nbsp;create/connect
            a&nbsp;wallet directly via the Unique Wallet on-line
            interface. When using Chrome or&nbsp;Firefox desktop with the&nbsp;Polkadot.js
            browser extension, set your account to&nbsp;“allow use on&nbsp;any chain”.
          </p>
          <p>
            Note that this option is&nbsp;not available to&nbsp;Ledger or&nbsp;Trust Wallet
            users. Support for these wallets will be&nbsp;added at&nbsp;a&nbsp;later date. If,
            by&nbsp;chance, you happened to&nbsp;transfer any tokens to&nbsp;one of&nbsp;the
            unsupported wallets, rest assured that your funds are safe in&nbsp;the
            wallet, but they are currently inaccessible until support for the
            wallet is&nbsp;added.
          </p>
        </>
      ),
      isNew: false
    },
    {
      title: <>How can I&nbsp;sell my&nbsp;NFTs?</>,
      content: (
        <>
          <p>
            If&nbsp;you want to&nbsp;sell your NFT, you need to&nbsp;click on&nbsp;the &quot;Sell tokens&quot;
            button and select the NFTs you wish to&nbsp;sell. However, if&nbsp;you have
            hundreds or&nbsp;thousands of&nbsp;NFTs that you want to&nbsp;sell, it&nbsp;would be
            easier to&nbsp;use the script for mass listings:
          </p>
          <p>
            <UILink
              href='https://github.com/UniqueNetwork/mass-selling'
              title='@unique2faucet_opal_bot'
              role='primary'
              rel='noopener noreferrer'
              target='_blank'
            >
              https://github.com/UniqueNetwork/mass-selling
            </UILink>
          </p>
        </>
      ),
      isNew: true
    },
    {
      title: <>What is&nbsp;nesting?</>,
      defaultExpanded: 'isNestedInfo' in state,
      content: (
        <>
          <p>
            Nesting is&nbsp;the creation of&nbsp;an&nbsp;on-chain connection between tokens.
            The elements are grouped into a&nbsp;nested tree-like structure within a&nbsp;single NFT.
            This NFT is&nbsp;called a&nbsp;bundle root. Tokens nested in&nbsp;a&nbsp;bundle
            are called a&nbsp;tree branches. Of&nbsp;the two connected tokens,
            a&nbsp;token with a&nbsp;connection that is&nbsp;closer to&nbsp;the root is&nbsp;considered a
            parent and all its other attachments children. In&nbsp;an&nbsp;ordered tree,
            each branch can have only one parent but a&nbsp;parent may have many
            children.
          </p>
          <p>
            Only an&nbsp;NFT can be&nbsp;a parent; however, a&nbsp;child can be&nbsp;any of&nbsp;the
            following: an&nbsp;NFT, a&nbsp;Fraction or&nbsp;a&nbsp;Coin. The owner of&nbsp;the bundle is&nbsp;the
            user (wallet), but the owner of&nbsp;the nested token is&nbsp;another
            token. The number of&nbsp;attachments is&nbsp;unlimited, but there can
            be&nbsp;no&nbsp;more than 5&nbsp;levels of&nbsp;nesting.
          </p>
        </>
      ),
      isNew: true
    },
    {
      title: <>What are Customizable NFTs and How Can I&nbsp;Create Them?</>,
      defaultExpanded: 'isCustomizableInfo' in state,
      content: (
        <>
          <p>
            Customizable NFTs are a&nbsp;type of&nbsp;digital asset that allows users
            to&nbsp;visually customize their NFTs by&nbsp;equipping other NFTs, thereby
            creating an&nbsp;on-chain connection between them.
          </p>
          <p>
            Customizable NFTs enable you to&nbsp;implement several business cases
            in&nbsp;your art or&nbsp;dApps, such as:
            <ol>
              <li>Gaming NFT skins</li>
              <li>Customizable NFT collections</li>
              <li>Customizable digital fashion</li>
              <li>Metaverse NFT wearables</li>
            </ol>
          </p>
          <p>
            For more information, you can check{' '}
            <UILink
              href='https://www.youtube.com/watch?v=JhgAS7YqJI0'
              title='Customizable NFTs explanation video'
              role='primary'
              rel='noopener noreferrer'
              target='_blank'
            >
              our video explanation
            </UILink>{' '}
            about Customizable NFTs here.
          </p>
          <p>
            If&nbsp;you want to&nbsp;create your first Customizable collection, please{' '}
            <UILink
              href='https://github.com/fend25/nesting-poc'
              title='instructions'
              role='primary'
              rel='noopener noreferrer'
              target='_blank'
            >
              check our instructions
            </UILink>.
          </p>
        </>
      ),
      isNew: true
    },
    {
      title: <>How do&nbsp;I nest and manipulate a&nbsp;nested token?</>,
      content: (
        <>
          <p>
            Nesting is&nbsp;performed by&nbsp;transferring a&nbsp;token to&nbsp;the address
            of&nbsp;a&nbsp;(parent) NFT. Both tokens must belong to&nbsp;the same owner. To&nbsp;nest
            a&nbsp;token one must own it. To&nbsp;perform the nesting click on&nbsp;&quot;Nest this
            token&quot; in&nbsp;the token details page. A&nbsp;modal window will display a&nbsp;list
            of&nbsp;collections (of&nbsp;which you are the owner) that allow nesting,
            as&nbsp;well as&nbsp;an opportunity to&nbsp;choose a&nbsp;token that you wish
            to&nbsp;nest. If&nbsp;the token you are nesting is&nbsp;an&nbsp;NFT it&nbsp;will
            automatically become the
            parent of&nbsp;subsequent nesting operations.
          </p>
          <p>
            Bundles and nested tokens can be&nbsp;stored in&nbsp;a wallet, transferred
            to&nbsp;other accounts, withdrawn and burned. Some important nesting rules
            to&nbsp;remember are:
          </p>
          <ol>
            <li>
              Transferring a&nbsp;parent transfers the entire branch below the parent
              with all the children.
            </li>
            <li>
              Only the owner of&nbsp;the parent token can withdraw the token from the
              bundle.
            </li>
            <li>
              A&nbsp;token that contains nested branches (a&nbsp;non-empty bundle) cannot
              be&nbsp;burned; for this, all branches must first be&nbsp;unnested. Only
              then will the &nbsp;Burn token&nbsp; menu item in&nbsp;the ellipsis menu (in the
              upper right corner) become accessible.
            </li>
          </ol>
          <p>
            The cost of&nbsp;minting the token depends on&nbsp;the number of&nbsp;properties
            specified.
          </p>
        </>
      ),
      isNew: false
    },
    {
      title: <>How do I add Royalties to my NFT Collection?</>,
      content: (
        <>
          <ol>
            <li>Go to <Link to={`/${activeNetwork}/collections`} target='_blank' rel='noopener noreferrer'>My collections</Link> page.</li>
            <li>Open your collection and click on “Edit collection”.</li>
            <li>Scroll down, at the end you can end Royalty percentage and address.</li>
          </ol>
        </>
      ),
      isNew: true
    },
    {
      title: <>Where can I&nbsp;get coins?</>,
      content: (
        <p>
          To&nbsp;obtain QTZ visit the{' '}
          <UILink
            href='https://www.mexc.com/exchange/QTZ_USDT'
            role='primary'
            rel='noopener noreferrer'
            target='_blank'
          >
            MEXC Exchange
            <Icon color='currentColor' name='arrow-up-right' size={16} />
          </UILink>
          <br />
          To&nbsp;obtain UNQ visit the{' '}
          <UILink
            href={cryptoExchangeUNQ}
            role='primary'
            rel='noopener noreferrer'
            target='_blank'
          >
            Huobi Global
            <Icon color='currentColor' name='arrow-up-right' size={16} />
          </UILink>
        </p>
      ),
      isNew: false
    },
    {
      title: <>I&nbsp;want to&nbsp;find my&nbsp;collections and tokens in&nbsp;the blockchain.
        Where can I&nbsp;search for them?</>,
      content: (
        <p>
          Use our{' '}
          <UILink
            href='https://uniquescan.io'
            rel='noopener noreferrer'
            target='_blank'
          >
            UniqueScan
            <Icon color='currentColor' name='arrow-up-right' size={16} />
          </UILink>
        </p>
      )
    },
    {
      title: <>Where can I&nbsp;read the Terms of&nbsp;Service?</>,
      content: (
        <p>
          You can read our Terms of&nbsp;Service{' '}
          <UILink
            href='https://ipfs.unique.network/ipfs/QmPvpkJBfr4hSscGwqkhLysA5ajYXspCxDLR2EaJxZHY8z'
            rel='noopener noreferrer'
            target='_blank'
          >
            here
          </UILink>.
        </p>
      ),
      isNew: false
    }
  ];
};
