import {
  CollectedNamespace,
  DecomposedNamespace,
} from '@unique-nft/common/types/nfts';

export function decomposeNamespace<T extends CollectedNamespace>(
  collectedNamespace: T,
): DecomposedNamespace & Omit<T, 'namespace'> {
  const { namespace, ...otherArgs } = collectedNamespace;
  const { type, account } = namespace;
  return {
    ...otherArgs,
    namespaceType: type,
    namespaceAccount: account,
  };
}
