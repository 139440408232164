import styled from 'styled-components';

export const ModalFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: var(--prop-gap);
  margin-top: var(--prop-gap);
`;
