import { useEffect, useState } from 'react';

import { ConfirmUpdateCollectionModal } from './ConfirmUpdateCollectionModal';
import { useAccounts } from 'hooks/useAccounts';
import { useCollectionRemoveSponsorship } from './hooks/useCollectionRemoveSponsorship';
import { Button, useNotifications } from 'components/UI';
import { StatusTransactionModal } from './StatusTransactionModal';

type Props = {
  collectionId?: number;
  onComplete?(): Promise<void>;
};

export const RemoveSponsorship = ({ collectionId, onComplete }: Props) => {
  const [isVisibleConfirmModal, setVisibleConfirmModal] = useState(false);
  const { selectedAccount } = useAccounts();

  const {
    feeFormatted,
    getFee,
    feeError,
    feeLoading,
    submitWaitResult,
    isLoadingSubmitResult
  } = useCollectionRemoveSponsorship();
  const { error, info } = useNotifications();
  useEffect(() => {
    if (feeError) {
      error(feeError);
    }
  }, [feeError]);

  useEffect(() => {
    if (!isVisibleConfirmModal || !collectionId || !selectedAccount?.address) {
      return;
    }
    getFee({ payload: collectionId });
  }, [collectionId, getFee, isVisibleConfirmModal, selectedAccount?.address]);

  const handleSubmit = async () => {
    if (!collectionId || !selectedAccount) {
      return;
    }

    setVisibleConfirmModal(false);

    try {
      await submitWaitResult({ payload: collectionId });
      await onComplete?.();
      info('The sponsorship removed successfully');
    } catch (e: any) {
      setVisibleConfirmModal(false);
    }
  };

  if (!collectionId || !selectedAccount?.address) {
    return null;
  }

  return (
    <>
      <Button
        title='Remove sponsorship'
        role='danger'
        onClick={() => setVisibleConfirmModal(true)}
      />
      <StatusTransactionModal
        isVisible={isLoadingSubmitResult}
        description='Removing sponsorship'
      />

      <ConfirmUpdateCollectionModal
        title='Remove sponsorship'
        isVisible={isVisibleConfirmModal}
        isLoading={feeLoading}
        fee={feeFormatted}
        onConfirm={handleSubmit}
        onClose={() => setVisibleConfirmModal(false)}
      />
    </>
  );
};
