import {
  NftsAccountArguments,
  NftsAccountCollectionsResult,
  NftsAccountOwnershipAcceptanceResult,
} from '@unique-nft/common/types/nfts';
import { Section } from '../../common';
import { INftsAccount } from '../../types';

export class NftsAccount extends Section implements INftsAccount {
  readonly getOwnershipAcceptance = (
    args: NftsAccountArguments,
  ): Promise<NftsAccountOwnershipAcceptanceResult> =>
    this.query('get-ownership-acceptance', args);

  readonly getCollections = (
    args: NftsAccountArguments,
  ): Promise<NftsAccountCollectionsResult> =>
    this.query('get-collections', args);
}
