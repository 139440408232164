import { Section } from '../common';
import {
  BatchingArgumentsBody,
  BatchingResultParsed,
  ChainPropertiesResponse,
  GetNonceQuery,
  GetNonceResponse,
  ICommon,
  Method,
} from '../types';
import { createMutationEx, MethodNameNull } from '../common/mutation';

export class Common extends Section implements ICommon {
  readonly chainProperties = (): Promise<ChainPropertiesResponse> =>
    this.query('chain-properties');

  readonly getNonce = (args: GetNonceQuery): Promise<GetNonceResponse> =>
    this.query('nonce', args);

  readonly batch = createMutationEx<
    BatchingArgumentsBody,
    BatchingResultParsed
  >(this.client, Method.POST, `${this.path}/batching`, MethodNameNull);
}
