import Axios, { AxiosInstance } from 'axios';

import { IBaseClient, IExtrinsics, Options } from '../types';
import { ApiError } from '../errors';

import { Extrinsic } from '../sections/extrinsic';

import { version } from '../../package.json';

export class BaseClient implements IBaseClient {
  public instance: AxiosInstance;

  public readonly options: Options;

  public readonly extrinsic: IExtrinsics;

  constructor(options: Options) {
    this.options = options;
    this.checkOptions();

    this.instance = Axios.create({
      baseURL: `${this.options.baseUrl}`,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      ...options.axiosConfig,
    });

    this.instance.interceptors.response.use(
      (response) => response,
      (exception) => {
        const err = exception.response?.data?.error;
        throw err && typeof err === 'object' ? new ApiError(err) : exception;
      },
    );

    if (version) {
      this.instance.defaults.headers.common['X-SDK-Version'] = version;
    }

    this.extrinsic = new Extrinsic(this, 'extrinsic');
  }

  private checkOptions() {
    const { baseUrl } = this.options;

    if (!this.options.baseUrl) {
      throw new Error(`'baseUrl' option is required`);
    }

    this.options.baseUrl = baseUrl.replace(/\/$/, '');

    if (!this.options.statusTotalTimeoutInMs) {
      this.options.statusTotalTimeoutInMs = 3 * 60 * 1000;
    }

    if (!this.options.maximumNumberOfStatusRequests) {
      this.options.maximumNumberOfStatusRequests = Number.MAX_SAFE_INTEGER;
    }

    if (!this.options.waitBetweenStatusRequestsInMs) {
      this.options.waitBetweenStatusRequestsInMs = 5_000;
    }
  }
}
