import { Text, Heading } from 'components/UI';

import { AttributesTable } from './AttributesTable';
import { FormBody, FormHeader } from '../components/FormComponents';

export const NFTAttributes = () => {
  return (
    <>
      <Heading size='2'>Submit collection create</Heading>
      {/* <FormHeader>
        <Heading size='2'>Token attributes</Heading>
        <Text>
          Customize your token — define your NFTs traits: name, accessory, gender,
          background, face, body, tier, etc.
        </Text>
      </FormHeader>
      <FormBody>
        <AttributesTable />
      </FormBody> */}
    </>
  );
};
