import {
  NftsCollectionConfigResult,
  NftsCollectionDestroyArguments,
  NftsCollectionGetResult,
  NftsCollectionSetTeamArguments,
  NftsCollectionSetTeamResult,
  NftsCollectionCreateArguments,
  NftsCollectionGetRoleArguments,
  NftsCollectionGetRoleResponse,
  NftsCollectionDestroyResult,
  NftsCollectionSetMaxSupplyArguments,
  NftsCollectionSetMaxSupplyResult,
  NftsCollectionSetAcceptOwnershipArguments,
  NftsCollectionSetAcceptOwnershipResult,
  NftsCollectionTransferOwnershipArguments,
  NftsCollectionTransferOwnershipResult,
  NftsCollectionSetMetadataArguments,
  NftsCollectionSetMetadataResult,
  NftsCollectionClearMetadataArguments,
  NftsCollectionClearMetadataResult,
  NftsCollectionGetMetadataResult,
  NftsCollectionLockArguments,
  NftsCollectionLockResult,
  NftsCollectionIsOwnerArguments,
  NftsCollectionIsOwnerResult,
  NftsCollectionGetItemsResult,
  NftsCollectionGetArguments,
  NftsCollectionGetAttributeArguments,
  NftsCollectionGetAttributeResult,
  NftsCollectionGetAttributesArguments,
  NftsCollectionGetAttributesResult,
  NftsCollectionSetAttributeArguments,
  NftsCollectionSetAttributeResult,
  NftsCollectionClearAttributeResult,
  NftsCollectionClearAttributeArguments,
  NftsCollectionAttributesApprovalsResult,
} from '@unique-nft/common/types/nfts';
import { createMutationEx } from '../../common/mutation';
import { INftsCollection, Method } from '../../types';
import { Section } from '../../common';
import { decomposeNamespace } from './utils';

export class NftsCollection extends Section implements INftsCollection {
  readonly create = createMutationEx<
    NftsCollectionCreateArguments,
    NftsCollectionGetResult
  >(this.client, Method.POST, `${this.path}`, {
    section: 'nfts',
    method: 'create',
  });

  readonly destroy = createMutationEx<
    NftsCollectionDestroyArguments,
    NftsCollectionDestroyResult
  >(this.client, Method.DELETE, `${this.path}`, {
    section: 'nfts',
    method: 'destroy',
  });

  readonly get = (
    args: NftsCollectionGetArguments,
  ): Promise<NftsCollectionGetResult> => this.query('', args);

  readonly getItems = (
    args: NftsCollectionGetArguments,
  ): Promise<NftsCollectionGetItemsResult> => this.query('get-items', args);

  readonly isOwner = (
    args: NftsCollectionIsOwnerArguments,
  ): Promise<NftsCollectionIsOwnerResult> => this.query('is-owner', args);

  readonly getConfig = (
    args: NftsCollectionGetArguments,
  ): Promise<NftsCollectionConfigResult> => this.query('config', args);

  readonly getRole = (
    args: NftsCollectionGetRoleArguments,
  ): Promise<NftsCollectionGetRoleResponse> => this.query('role', args);

  readonly setTeam = createMutationEx<
    NftsCollectionSetTeamArguments,
    NftsCollectionSetTeamResult
  >(this.client, Method.POST, `${this.path}/set-team`, {
    section: 'nfts',
    method: 'setTeam',
  });

  readonly setMaxSupply = createMutationEx<
    NftsCollectionSetMaxSupplyArguments,
    NftsCollectionSetMaxSupplyResult
  >(this.client, Method.POST, `${this.path}/set-max-supply`, {
    section: 'nfts',
    method: 'setCollectionMaxSupply',
  });

  readonly setAcceptOwnership = createMutationEx<
    NftsCollectionSetAcceptOwnershipArguments,
    NftsCollectionSetAcceptOwnershipResult
  >(this.client, Method.POST, `${this.path}/set-accept-ownership`, {
    section: 'nfts',
    method: 'setAcceptOwnership',
  });

  readonly transferOwnership = createMutationEx<
    NftsCollectionTransferOwnershipArguments,
    NftsCollectionTransferOwnershipResult
  >(this.client, Method.POST, `${this.path}/transfer-ownership`, {
    section: 'nfts',
    method: 'transferOwnership',
  });

  readonly setMetadata = createMutationEx<
    NftsCollectionSetMetadataArguments,
    NftsCollectionSetMetadataResult
  >(this.client, Method.POST, `${this.path}/set-metadata`, {
    section: 'nfts',
    method: 'setCollectionMetadata',
  });

  readonly clearMetadata = createMutationEx<
    NftsCollectionClearMetadataArguments,
    NftsCollectionClearMetadataResult
  >(this.client, Method.POST, `${this.path}/clear-metadata`, {
    section: 'nfts',
    method: 'clearCollectionMetadata',
  });

  readonly getMetadata = (
    args: NftsCollectionGetArguments,
  ): Promise<NftsCollectionGetMetadataResult> =>
    this.query('get-metadata', args);

  readonly lock = createMutationEx<
    NftsCollectionLockArguments,
    NftsCollectionLockResult
  >(this.client, Method.POST, `${this.path}/lock`, {
    section: 'nfts',
    method: 'lockCollection',
  });

  readonly getAttribute = (
    args: NftsCollectionGetAttributeArguments,
  ): Promise<NftsCollectionGetAttributeResult> =>
    this.query('get-attribute', decomposeNamespace(args));

  readonly getAttributes = (
    args: NftsCollectionGetAttributesArguments,
  ): Promise<NftsCollectionGetAttributesResult> =>
    this.query('get-attributes', decomposeNamespace(args));

  readonly setAttribute = createMutationEx<
    NftsCollectionSetAttributeArguments,
    NftsCollectionSetAttributeResult
  >(this.client, Method.POST, `${this.path}/set-attribute`, {
    section: 'nfts',
    method: 'setAttribute',
  });

  readonly clearAttribute = createMutationEx<
    NftsCollectionClearAttributeArguments,
    NftsCollectionClearAttributeResult
  >(this.client, Method.POST, `${this.path}/clear-attribute`, {
    section: 'nfts',
    method: 'clearAttribute',
  });

  readonly getAttributesApprovals = (
    args: NftsCollectionGetArguments,
  ): Promise<NftsCollectionAttributesApprovalsResult> =>
    this.query('get-attributes-approvals', args);
}
