import {
  NftsNextCollectionResult,
  NftsPalletVersionResult,
} from '@unique-nft/common/types/nfts';
import { Section } from '../../common';
import { INftsCommon } from '../../types';

export class NftsCommon extends Section implements INftsCommon {
  readonly getPalletVersion = (): Promise<NftsPalletVersionResult> =>
    this.query('pallet-version', {});

  readonly getNextCollectionId = (): Promise<NftsNextCollectionResult> =>
    this.query('next-collection-id', {});
}
