
export const blockedSellingCollections: Record<string, number[]> = {
  quartz: [1, 2, 796],
  unique: [126, 304, 305]
};

export const blockedTransferringCollections: Record<string, number[]> = {
  quartz: [1, 2],
  unique: [304]
};

export const blockedNestingCollections: Record<string, number[]> = {
  quartz: [1, 2],
  unique: []
};
