import { IBaseClient, INfts } from '../../types';
import { NftsCollection } from './collection';
import { Section } from '../../common';
import { NftsItem } from './item';
import { NftsAccount } from './accounts';
import { NftsCommon } from './common';
import { NftsPreSign } from './pre-sign';

export class Nfts extends Section implements INfts {
  public readonly common: NftsCommon;

  public readonly account: NftsAccount;

  public readonly collection: NftsCollection;

  public readonly item: NftsItem;

  public readonly preSign: NftsPreSign;

  constructor(client: IBaseClient, path: string) {
    super(client, path);

    this.common = new NftsCommon(client, `${path}/common`);
    this.account = new NftsAccount(client, `${path}/accounts`);
    this.collection = new NftsCollection(client, `${path}/collections`);
    this.item = new NftsItem(client, `${path}/items`);
    this.preSign = new NftsPreSign(client, `${path}/pre-sign`);
  }
}
