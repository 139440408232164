export const Primary700 = 'var(--color-primary-700)';
export const Primary600 = 'var(--color-primary-600)';
export const Primary500 = 'var(--color-primary-500)';
export const Primary400 = 'var(--color-primary-400)';
export const Primary300 = 'var(--color-primary-300)';
export const Primary200 = 'var(--color-primary-200)';
export const Primary100 = 'var(--color-primary-100)';
export const Secondary700 = 'var(--color-secondary-700)';
export const Secondary600 = 'var(--color-secondary-600)';
export const Secondary500 = 'var(--color-secondary-500)';
export const Secondary400 = 'var(--color-secondary-400)';
export const Secondary300 = 'var(--color-secondary-300)';
export const Secondary200 = 'var(--color-secondary-200)';
export const Secondary100 = 'var(--color-secondary-100)';
export const BlueGrey700 = 'var(--color-blue-grey-700)';
export const BlueGrey600 = 'var(--color-blue-grey-600)';
export const BlueGrey500 = 'var(--color-blue-grey-500)';
export const BlueGrey400 = 'var(--color-blue-grey-400)';
export const BlueGrey300 = 'var(--color-blue-grey-300)';
export const BlueGrey200 = 'var(--color-blue-grey-200)';
export const BlueGrey100 = 'var(--color-blue-grey-100)';
export const Grey700 = 'var(--color-grey-700)';
export const Grey600 = 'var(--color-grey-600)';
export const Grey500 = 'var(--color-grey-500)';
export const Grey400 = 'var(--color-grey-400)';
export const Grey300 = 'var(--color-grey-300)';
export const Grey200 = 'var(--color-grey-200)';
export const Grey100 = 'var(--color-grey-100)';
export const Coral700 = 'var(--color-coral-700)';
export const Coral600 = 'var(--color-coral-600)';
export const Coral500 = 'var(--color-coral-500)';
export const Coral400 = 'var(--color-coral-400)';
export const Coral300 = 'var(--color-coral-300)';
export const Coral200 = 'var(--color-coral-200)';
export const Coral100 = 'var(--color-coral-100)';
export const AdditionalLight = 'var(--color-additional-light)';
export const AdditionalDark = 'var(--color-additional-dark)';
export const AdditionalPositive500 = 'var(--color-additional-positive-500)';
export const AdditionalPositive100 = 'var(--color-additional-positive-100)';
export const AdditionalWarning500 = 'var(--color-additional-warning-500)';
export const AdditionalWarning100 = 'var(--color-additional-warning-100)';
export const AdditionalCoral500 = 'var(--color-additional-coral-500)';
