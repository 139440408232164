/**
 * Error thrown when the maximum number of status requests has been reached.
 */
export class MaxStatusRequestsError extends Error {
  details: string;

  txHash: string;

  limit: number;

  constructor(txHash: string, limit: number) {
    const message = `Extrinsic status was not changed within the specified count of status requests (${limit}).`;

    super(message);

    this.details =
      `${message} This does not mean that the Extrinsic has failed nor that it will be included in the future.` +
      ' You can check the status of the Extrinsic by calling the getStatus() method.';

    this.txHash = txHash;
    this.limit = limit;
  }
}
