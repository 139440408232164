import { useCallback, useEffect, useState } from 'react';
import { useNotifications } from 'components/UI';
import { InternalStage, StageStatus, useStagesReturn } from '../types/StagesTypes';
import { Account } from 'account/types';

const useStages = <T>(stages: InternalStage<T>[], account?: Account): useStagesReturn<T> => {
  if (!account) throw new Error('Can\'t execute stages without account');
  const [internalStages, setInternalStages] = useState<InternalStage<T>[]>(stages);
  const [stagesStatus, setStagesStatus] = useState<StageStatus>(StageStatus.default);
  const [executionError, setExecutionError] = useState<Error | undefined | null>(null);
  const { error } = useNotifications();

  useEffect(() => {
    setInternalStages(stages);
  }, [stages]);

  useEffect(() => {
    return () => {
      internalStages?.forEach((internalStage) => internalStage?.signer?.onError(new Error('Componen\'t unmounted')));
    };
  }, [internalStages]);

  const updateStage = useCallback((index: number, newStage: InternalStage<T>) => {
    setInternalStages((stages) => {
      const copy = [...stages];
      copy[index] = newStage;
      return copy;
    });
  }, [setInternalStages]);

  const executeStep = useCallback(async (stage: InternalStage<T>, index: number, txParams: T) => {
    updateStage(index, { ...stage, status: StageStatus.inProgress });
    try {
      // if sign is required by action -> promise wouldn't be resolved until transaction is signed
      // transaction sign could be triggered in the component that uses current stage (you can track it by using stage.account)
      await stage.action({ txParams, options: { account } });
      updateStage(index, { ...stage, status: StageStatus.success });
    } catch (e: any) {
      updateStage(index, { ...stage, status: StageStatus.error });
      console.error('Execute stage failed', stage, e);
      throw new Error(`Execute step "${stage.title}" failed: ${e?.message}`);
    }
  }, [updateStage, account]);

  const initiate = useCallback(async (params: T) => {
    setStagesStatus(StageStatus.inProgress);
    for (const [index, internalStage] of internalStages.entries()) {
      try {
        await executeStep(internalStage, index, params);
      } catch (e) {
        setStagesStatus(StageStatus.error);
        setExecutionError(new Error(`Stage "${internalStage.title}" failed`));
        error(
          `Stage "${internalStage.title}" failed`,
          { name: 'warning', size: 16, color: 'var(--color-additional-light)' }
        );
        return;
      }
    }
    setStagesStatus(StageStatus.success);
  }, [executeStep, internalStages]);

  return {
    // we don't want our components to know or have any way to interact with stage.actions, everything else is fine
    // TODO: consider to split them apart like InternalStages = [{ stage, action }, ...] instead
    stages: internalStages.map((internalStage: InternalStage<T>) => {
      const { action, ...other } = internalStage;
      return {
        ...other
      };
    }),
    error: executionError,
    status: stagesStatus,
    initiate
  };
};

export default useStages;
